@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in-delayed {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out 0.5s, transform 1s ease-in-out 0.5s;
}

.animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.scale-in {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.scale-in-delayed {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 1s ease-in-out 0.5s, transform 1s ease-in-out 0.5s;
}

.scale-in.animate {
  opacity: 1 !important;
  transform: scale(1) !important;
}

.zoom-in {
  animation: zoomIn 1s ease-in-out;
}

.hover-rotate img {
  transition: transform 0.3s ease-in-out;
}

.hover-rotate:hover img,
.hover-rotate:focus img {
  transform: rotate(5deg);
}

@keyframes underlineExpand {
  from {
    width: 10%;
  }
  to {
    width: 100%;
  }
}

.underline-purple {
  position: relative;
  display: inline-block;
}

.underline-purple::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust as needed */
  width: 10%; /* Start with width 10% */
  height: 3px; /* Adjust as needed */
  background-color: #6930c3;
  border-radius: 5px;
  animation: underlineExpand 1s ease-in-out forwards; /* Run the animation */
}

/* Ensure navbar and image are visible */
.navbar {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

img {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

/* Media queries for responsive design */
@media (min-width: 2000px) {
  .scale-in,
  .scale-in-delayed {
    transform: scale(1);
    opacity: 1;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }
}

/* Line styling */
.line-container {
  position: relative;
  width: 100%;
}

.line {
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  background-color: #6930c3; /* Ensure the line color is visible */
  transition: height 0.3s ease-in-out;
  z-index: 1; /* Ensure the line is above other elements */
}

.dot {
  position: absolute;
  left: 0; /* Align dot with the line */
  width: 12px;
  height: 12px;
  background-color: #6930c3; /* Ensure the dot color is visible */
  border-radius: 50%;
  transform: translateX(-50%);
  display: none;
  z-index: 2; /* Ensure the dot is above other elements */
}

.section.in-view .dot {
  display: block;
}

/* Section styling */
.section {
  padding: 20px;
  margin-bottom: 20px;
  border-left: 4px solid transparent;
  border-radius: 10px;
  color: white; /* Ensure text color is white */
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Add background color for visibility */
  position: relative; /* Ensure the section is positioned correctly */
  z-index: 0; /* Ensure the section is below the line and dot */
}

.section.in-view {
  border-left-color: #6930c3;
}

.sections-container {
  display: flex;
  flex-direction: column;
}

.section-wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
}
